<template>
  <div
    :style="
      $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? 'padding-top: 0px'
        : 'padding-top: 50px'
    "
  >
    <v-layout wrap>
      <v-flex xs12>
        <Clients />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Clients from "@/components/Clients/clients";
export default {
  components: {
    Clients,
  },
};
</script>