<template>
  <div>
 
    <v-layout
      wrap
      pa-2
      py-8
      pa-sm-16
      pa-md-6
      pa-lg-16
      style="background-color: #f2f2f2"
    >
      <v-flex xs4 sm2 md2 lg1 pl-2 pb-8 text-left>
        <span style="border-bottom: 3px solid #b88726">Our Clients</span>
        <!-- <v-progress-linear
          background-color="pink lighten-3"
          color="#B88726"
          value="100"
        ></v-progress-linear> -->
      </v-flex>
      <v-flex xs12 v-if="works.length > 0" align-self-center>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            px-md-3
            px-2
            py-2
            sm6
            md4
            lg3
            v-for="(item, i) in works"
            :key="i"
          >
         
              <v-layout wrap>
                <v-flex xs12 md12 lg12 xl2 align-self-start text-left>
                  <v-img
                    contain
                 height="80px"
                    :src="mediaURL + item.image"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
              </v-layout>
                     <v-layout wrap pa-4 pa-md-2 pa-lg-4 pa-xl-6>
                <!-- <v-flex
                  style="min-height: 60px !important"
                  xs12
                  lg11
                  py-2
                  align-self-center
                >
                  <span class="bantitle16">{{ item.company }}</span>
                </v-flex> -->
                <!-- <v-flex xs12 align-self-center>
                  <v-layout wrap>
                    <v-flex xs10 align-self-center>
                      <span class="bantitle17">{{ item.title.slice(0,190) }}</span>
                    </v-flex>
                    <v-flex
                      @click="$router.push('product?id=' + item._id)"
                      style="cursor: pointer"
                      align-self-end
                      text-right
                    >
                      <v-icon color="#C0831E">mdi-arrow-right</v-icon>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
              </v-layout>
          
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-5 v-else align-self-center>
        <span span class="bantitle15">No data found.</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      headerData: {},
      works: [],
      appLoading: false,
      ServerError: false,
      msg: null,
    };
  },
  beforeMount() {
    this.getData();
    // this.getCategoryData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "home/client/getlist",
      
      })
        .then((response) => {
          if (response.data.status) {
            this.works = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategoryData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/category/get",
        params: {
          id: this.$route.query.category,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.headerData = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.prodGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #f5a624 0%, #ffc837 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
</style>